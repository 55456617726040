import {message} from "antd";

// 防抖函数触发时的时间戳字段
let CURRENT_TIME;

/**
 * 判断对象是否为空
 * @param obj 对象
 * @returns {boolean}
 */
export const isObjectEmpty = obj => Reflect.ownKeys(obj).length === 0 && obj.constructor === Object;
/**
 * 不会进行四舍五入，而是直接截断小数点后第二位及以后的数字。
 * @param num
 * @returns {number}
 */
export const getOneDecimalPlace = (num) => {
    return Math.trunc(num * 10) / 10;
}
/**
 * 随机打乱数组
 * @param array
 * @returns {*}
 */
export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

/**
 * 判断两个对象是否完全相等
 * @param obj1 第一个对象
 * @param obj2 第二个对象
 * @returns {boolean|this is string[]}
 */
export const isObjectEqual = (obj1, obj2) => {
    const KEY = {OBJECT: "object", ARRAY: "array", FUNCTION: "function"};
    const keys1 = Object.getOwnPropertyNames(obj1);
    const keys2 = Object.getOwnPropertyNames(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    return keys1.every(key => {
        const TYPE = typeof obj1[key];

        if (!!obj1[key] && TYPE === KEY.OBJECT) {
            return isObjectEqual(obj1[key], obj2[key]);
        }

        if (TYPE === KEY.ARRAY) {

            if (obj1[key].length !== obj2[key].length) {
                return false;
            }
            return obj1[key].every((child, index) => {
                const CHILD_TYPE = typeof child;

                if ([KEY.ARRAY].includes(CHILD_TYPE)) {
                    return true;
                }

                if ([KEY.OBJECT, KEY.FUNCTION].includes(CHILD_TYPE)) {
                    const OBJ2_CHILD = obj2[key][index];
                    if (CHILD_TYPE !== typeof OBJ2_CHILD) {
                        return false;
                    }
                    return isObjectEqual(child, OBJ2_CHILD);
                }

                return child === obj2[key][index];

            });
        }

        if (TYPE === KEY.FUNCTION) {
            return obj1[key].toString() === obj2[key].toString();
        }

        return obj1[key] === obj2[key];

    });
};

/**
 * 深拷贝数组或对象
 * @param obj 需要被拷贝的数组或者对象
 * @returns {*[]}
 */
export const arrayOrObjCopy = (obj) => {
    let newObj = obj.constructor === Array ? [] : {};
    if (typeof obj !== "object") {
        return null;
    }
    for (let i in obj) {
        newObj[i] = typeof obj[i] === "object" ? arrayOrObjCopy(obj[i]) : obj[i];
    }
    return newObj;
};

/**
 * 判断两数组是否有相同元素, true: 存在相同 false：没相同
 * @param arr 第一个数组
 * @param otherArr 第二个数组
 * @returns {boolean}
 */
export const isArrayAnySame = (arr, otherArr) => {
    // 去重合并数组
    const normalArr = [...[...new Set(arr)], ...[...new Set(otherArr)]];
    // 再对合并数组去重
    const setArr = [...new Set(normalArr)];
    return normalArr.length !== setArr.length;
};

/**
 * 判断两个数组是否完全相同
 * @param arr 第一个数组
 * @param otherArr 第二个数组
 * @returns {boolean}
 */
export const isArrayAllSame = (arr, otherArr) => {
    // 去重合并数组
    const normalArr = [...arr, ...otherArr];
    // 再对合并数组去重
    const setArr = [...new Set(normalArr)];
    return normalArr.length === setArr.length * 2;
};

/**
 * 树形结构转JSON数据
 * @param data 数据源
 * @param flatData 存放转换数据数组
 * @param childrenName 子数据字段名称
 * @returns {*[]}
 */
export const treeToJson = (data, flatData = [], childrenName = "children") => {

    if (data.length < 1) {
        return [];
    }

    for (const item of data) {
        if (!item[childrenName]) {
            flatData.push(item);
        } else {
            treeToJson(item[childrenName], flatData, childrenName);
            delete item[childrenName];
            flatData.push(item);
        }
    }

    return flatData;
};

/**
 * JSON数据转树形结构
 * @param data 数据源
 * @param parentId 父id
 * @param flatData 存放转换后的数据数组
 * @returns {*[]}
 */
export const jsonToTree = (data, parentId = "0", flatData = []) => {

    for (const item of data) {
        if (item.parentId === parentId) {
            flatData.push(item);
        }
    }

    for (const item of flatData) {
        item.children = [];
        jsonToTree(data, item.id, item.children);
        if (item.children.length < 1) {
            delete item.children;
        }
    }

    return flatData;

};

/**
 * antd form 表单 错误提示
 * @param ref  表单ref引用
 * @param errData  数组对象  {name:"",errors:[""]}
 */
export const setFormError = (ref, errData = []) => {
    ref.current.setFields(errData);
};

/**
 * 请求错误时 弹出提示
 * @param err 错误信息
 */
export const alertErrorMessage = (err) => {
    if (typeof err === "object") {
        for (const errKey in err.data) {
            message.error(err[errKey]);
        }
    }
    if (typeof err === "string") {
        message.error(err);
    }
};

/**
 * 节流器
 * @param time 触发时间间隔
 */
export const throttleValve = (time = 800) => {
    let nowTime = new Date().getTime();
    if ((nowTime - CURRENT_TIME) >= time || CURRENT_TIME === undefined) {
        CURRENT_TIME = nowTime;
        // 正常逻辑应该返回 true 但为了外面业务代码好写 所以返回false
        return false;
    }
    return true;
};

/**
 * 防抖函数
 * @param fn 被触发函数
 * @param time 防抖时间
 */
export const debounce = (fn, time = 800) => {
    // 掉用节流器 防止函数被重复触发多次
    if (throttleValve(time)) return;
    let timer = null;
    return (function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn();
        }, time);
    })();
};
/**
 * 获取今天 年月日时分秒 通过参数控制 yyyy-MM-dd :返回年月日  yyyy-MM-dd HH:mm:返回年月日时分秒
 * @param type yyyy-MM-dd  yyyy-MM-dd HH:mm:ss
 */
export const getToday = (type = "yyyy-MM-dd") => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    return type === "yyyy-MM-dd" ? `${year}-${month}-${day}` : `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const toValuesForToday = (data) => {
    // 获取今天的日期，格式化为 'YYYY-MM-DD'
    const today = new Date().toISOString().slice(0, 10);

    // 遍历数据数组
    data.forEach(day => {
        // 检查是否为今天的日期
        if (day.date === today) {
            // 计算 detail 数组中所有 value 的总和
            let totalValue = day.detail.reduce((sum, item) => sum + item.value, 0);

            // 如果总和不等于24，则计算差值并添加新的对象到数组第一位
            if (totalValue < 24) {
                const diff = 24 - totalValue;
                day.detail.unshift({ status: 4, value: diff });
            }
        }
    });

    return data;
}

export const formatDate = (date, format = 'YYYY-MM-DD')=> {
    if (!(date instanceof Date) || isNaN(date)) {
        throw new TypeError('Invalid date');
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');

    // 创建一个映射对象，用于替换模板中的占位符
    const replacements = {
        'YYYY': year,
        'YY': String(year).slice(-2),
        'MM': month,
        'DD': day,
        'HH': hour,
        'hh': hour % 12 || 12, // 12小时制
        'mm': minute,
        'ss': second,
        'A': hour >= 12 ? 'PM' : 'AM',
        'a': hour >= 12 ? 'pm' : 'am'
    };

    // 使用正则表达式替换模板中的占位符
    return format.replace(/YYYY|YY|MM|DD|HH|hh|mm|ss|A|a/g, match => replacements[match]);
}
