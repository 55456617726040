import {Button, Col, Divider, Drawer, Row, Space, Table, Tag, Timeline, Tooltip, Typography} from "antd";
import React, {Component} from "react";
import RemoteApi from "../../../../../api/remote/RemoteApi";
import {ProDescriptions} from "@ant-design/pro-components";
import {EyeOutlined} from "@ant-design/icons";
import HistorApi from "../../../../../api/remote/HistoryApi";
import PreviewSmpWorkorderDrawer from "./PreviewSmpWorkorderDrawer";

export default class PreviewRecordDrawer extends Component {
    state = {
        drawerWidth: 700,
        id: null,
        sn: null,
        WorkorderNumber: null,
        deviceInfo: {},
        loading: false,
        show: false,
    }
    previewSmpRef = React.createRef();
    dialog = (id, ref) => ref.current.show(id);
    show = (entity) => {
        this.setState({
            show: true,
            id: entity.id,
            sn: entity.deviceNumber,
            drawerWidth: window.innerWidth < 576 ? window.innerWidth : window.innerWidth * 0.55
        }, () => {
            this.requestRemoteDeviceInfo(this.state.sn)
            this.requestRemoteRecordDeviceInfo(this.state.id)
        });
    };

    close = () => this.setState({show: false, loading: false});
    reload = () => {
    };
    /**
     * 查询远程操作信息
     */
    requestRemoteRecordDeviceInfo = id => {
        return HistorApi.find(id).then(res => {
            let { associatedWorkorderNumber } = res.data
            this.setState({WorkorderNumber: associatedWorkorderNumber})
            this.setState({recordDeviceInfo: res.data})
        }).catch(err => {
            console.error("request remote device info failed", err)
        })
    }
    /**
     * 查询远程设备信息
     */
    requestRemoteDeviceInfo = sn => {
        return HistorApi.findBySn(sn).then(res => {
            this.setState({deviceInfo: res.data})
        }).catch(err => {
            console.error("request remote device info failed", err)
        })
    }
    render()
    {
        return (
            <Drawer
                title="查看远程操作历史"
                width={this.state.drawerWidth}
                onClose={this.close}
                open={this.state.show}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Divider orientation="left">操作详情</Divider>
                <ProDescriptions bordered size="small" layout="horizontal" column={window.innerWidth < 800 ? 1 : 4}
                                 dataSource={this.state.recordDeviceInfo}>
                    <ProDescriptions.Item label="远程操作编号" span={4} dataIndex="id"/>
                    <ProDescriptions.Item label="操作时间" span={4} dataIndex="startTime"/>
                    <ProDescriptions.Item label="操作视频" span={4}>
                        <video width="320" controls autoplay src="https://www.runoob.com/try/demo_source/movie.mp4"></video>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="操作人" span={4} dataIndex="operator"/>
                    <ProDescriptions.Item label="关联工单编号" span={4}>
                        <Button type="link" size="small"
                                onClick={() => this.dialog(this.state.WorkorderNumber, this.previewSmpRef)}>{this.state.WorkorderNumber}</Button>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="关联客户单位" span={4} dataIndex="relatedCustomerDepartment"/>
                    <ProDescriptions.Item label="单位详细地址" span={4} dataIndex="createTime"/>
                </ProDescriptions>

                <Divider orientation="left">设备详情</Divider>
                <ProDescriptions bordered size="small" layout="horizontal" column={window.innerWidth < 800 ? 1 : 4} dataSource={this.state.deviceInfo}>
                    <ProDescriptions.Item label="设备名称" span={4} dataIndex="name"/>
                    <ProDescriptions.Item label="设备sn码" span={4} dataIndex="sn"/>
                    <ProDescriptions.Item label="客户单位" span={4} dataIndex={["customerDepartmentObj", "name"]}/>
                    <ProDescriptions.Item label="客户" span={4} dataIndex={["customerObj", "name"]}/>
                    <ProDescriptions.Item label="创建时间" span={4} dataIndex="createTime"/>
                    <ProDescriptions.Item label="修改时间" span={4} dataIndex="modifyTime"/>
                </ProDescriptions>
                <PreviewSmpWorkorderDrawer onSuccess={this.reload} ref={this.previewSmpRef}/>
            </Drawer>
        )
    }
}