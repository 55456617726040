import {get} from "../../https";

const DepartmentApi = {
    /**
     * 查询客户单位
     * @param id 客户单位 ID
     */
    find: (id) => get(`/customer/department/${id}`),
    /**
     * 根据父单位id查询所有子单位id
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findChildId: params => get("/customer/department/children/id", params),

    /**
     * 查询单位树
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDepartmentTree: params => get("/customer/departments/tree", params),

    /**
     * 查询单位数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDepartmentNumber: params => get("/customer/department/statistics/count", params),

    /**
     * 查询所有单位
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAllDepartment: params => get("/customer/department/list", params),

    /**
     * 查询单位详情
     * @param departmentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDepartmentDetail: departmentId => get(`/customer/department/${departmentId}`)

}

export default DepartmentApi;
