import {get, post} from "../../https/index";
import dayjs from "dayjs";

const RemoteApi = {
    /**
     * 分页查询
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => {

        if (params.time){
            //console.log(dayjs(params.time[0]).format('YYYY-MM-DD'),dayjs(params.time[1]).format('YYYY-MM-DD'))
           params.empowerStartTime = dayjs(params.time[0]).format('YYYY-MM-DD');
           params.empowerEndTime = dayjs(params.time[1]).format('YYYY-MM-DD');
           delete params.time;
        }
        return get("/resource/ahri/ahri/page", {params})
    },

    /**
     * 查询远程设备信息
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => get(`/resource/ahri/ahri/${id}`),

    /**
     * 新建
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: params => post("/resource/ahri", params),

    /**
     * 连接设备
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    connectDevice: id => get(`/resource/ahri/ahri/remote/${id}`),

    /**
     * 分页查询远程连接设备日志
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    disconnectDeviceLogPage: params => get(`/resource/ahri/log/page/`, {params}),
    /**
     * 远程连接设备全部日志
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    disconnectDeviceLogs: params => get(`/resource/ahri/log/logs`,params),
    /**
     * 查询所有设备的经纬度
     * @returns {Promise<AxiosResponse<any>>}
     */
    getLnglatEquipment: () => get("/resource/ahri/ahri/lnglat/list")
}

export default RemoteApi;
