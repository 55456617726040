import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {anyUserRoles} from "utils/securityCheck";
import Login from "../page/login";
import Home from "../page/home/Home";
import CustomerLogin from "../page/login/CustomerLogin";

import EngineerMap from "../page/home/engineer/map/EngineerMap";
import HomePage from "../page/home/home/HomePage";
import EngineerDetail from "../page/home/engineer/detail/EngineerDetail";
import DepartmentDetail from "../page/home/engineer/department/DepartmentDetail";

import StorehouseMap from "../page/home/storehouse/StorehouseMap";

import DeviceMap from "../page/home/device/DeviceMap";
import DeviceDetail from "../page/home/device/detail/DeviceDetail";

import WorkorderPage from "../page/home/workorder/WorkorderPage";
import WorkorderCountyPage from "../page/home/workorder/WorkorderCountyPage"

import AreaMonitor from "../page/home/area/AreaMonitor";

import DepartmentPage from "../page/home/department/DepartmentPage";
import DepartmentChildDetail from "../page/home/department/DepartmentChildDetail";
import WorkorderDetail from "../page/home/workorder/detail/WorkorderDetail";
import DepartmentDeviceList from "../page/home/department/DepartmentDeviceList";
import ManuFacturer from "../page/home/manuFacturer/ManuFacturer";
import FeviceTypeScores from "../page/home/manuFacturer/FeviceTypeScores";
import RemoteDeviceDetail from "../page/home/device/detail/RemoteDeviceDetail";

/**
 * 路由集合
 * isShow 判断该menu 是否需要在页面上渲染出来
 * @type {[{path: string, children: [{path: string, component: JSX.Element, name: string, isShow: boolean}], name: string, icon: JSX.Element, isShow: boolean}]}
 */
const routes = [
    {
        path: "/home/page",
        name: "首页",
        component: <HomePage/>
    },
    {
        path: "/home/engineer-map",
        name: "工程师地图",
        component: <EngineerMap/>
    },
    {
        path: "/home/engineer-detail",
        name: "工程师详情",
        component: <EngineerDetail/>
    },
    {
        path: "/home/department-detail",
        name: "机构详情",
        component: <DepartmentDetail/>
    },
    {
        path: "/home/storehouse-map",
        name: "仓库地图",
        component: <StorehouseMap/>
    },
    {
        path: "/home/device-map",
        name: "设备地图",
        component: <DeviceMap/>
    },
    {
        path: "/home/manufacturer",
        name: "厂商",
        component: <ManuFacturer/>
    },
    {
        path: "/home/fevicetypescores",
        name: "设备类型评分",
        component: <FeviceTypeScores/>
    },
    {
        path: "/home/device-detail",
        name: "设备详情",
        component: <DeviceDetail/>
    },
    {
        path: "/home/remote-device-detail",
        name: "远程设备详情",
        component: <RemoteDeviceDetail/>
    },
    {
        path: "/home/workorder",
        name: "工作调度",
        component: <WorkorderPage/>
    },
    {
        path: "/home/zgq/workorder",
        name: "工作调度",
        component: <WorkorderCountyPage/>
    },
    {
        path: "/home/workorder/detail",
        name: "工单详情",
        component: <WorkorderDetail/>
    },
    {
        path: "/home/area",
        name: "区域监控",
        component: <AreaMonitor/>
    },

    {
        path: "/home/department/:departmentId",
        name: "客户单位",
        component: <DepartmentPage/>
    },
    {
        path: "/home/department/device",
        name: "客户单位设备",
        component: <DepartmentDeviceList/>
    },
    {
        path: "/home/department-child-detail",
        name: "客户单位子单位",
        component: <DepartmentChildDetail/>
    },
];

/**
 * 渲染 路由
 * @returns {Promise<unknown>}
 */
export const renderMenuItem = () => {
    return new Promise(resolve => {
        resolve(filterMenuItem(routes));
    });
};

/**
 * 过滤出可在页面渲染的 路由
 * @param routes
 * @returns {*[]}
 */
export const filterMenuItem = (routes) => {
    if (routes === null || routes === undefined || routes.length === 0) {
        return [];
    }

    const resultMenu = [];

    for (const route of routes) {
        /**
         *  item?.roles ===> 当item上没有roles属性时返回 undefined
         *  anyUserRoles 函数 当  传入参数为 undefined 时 返回true
         *  当 用户具有路由上的权限 或者 路由上没有 roles 并且 isShow 为 true 是渲染menu
         */
        if (anyUserRoles(route?.roles) && (route.isShow || route?.isShow === undefined)) {
            resultMenu.push({...route, children: filterMenuItem(route.children)});
        }
    }

    return resultMenu;

};

/**
 * 筛选 可渲染路由
 * @param route
 * @param router
 * @returns {*[]}
 */
const getRoutes = (route = routes, router = []) => {
    route.forEach(item => {
        if (!item.children) {
            /**
             *  item?.roles ===> 当item上没有roles属性时返回 undefined
             *  anyUserRoles 函数 当  传入参数为 undefined 时 返回true
             *  当 用户具有路由上的权限 或者 路由上没有 roles 属性时 渲染路由
             */
            if (anyUserRoles(item?.roles)) {
                router.push(item);
            }
        } else {
            getRoutes(item.children, router);
        }
    });

    return router;
};

/**
 * 移除首屏加载动画
 */
const removeLaunchAnimation = () => {
    const loadingContainer = document.getElementById("loading");
    if (!!loadingContainer && !!loadingContainer.parentElement) {
        loadingContainer.parentElement.removeChild(loadingContainer);
    }
};

/**
 * 路由渲染
 * @returns {JSX.Element}
 */
export const renderRoutes = () => {
    // 调用移除首屏加载动画方法
    removeLaunchAnimation();
    return <Router>
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/customer/login" element={<CustomerLogin/>}/>
            <Route path="/home" element={<Home/>}>
                {getRoutes().map(item => <Route path={item.path} element={item.component} key={item.path}/>)}
            </Route>
        </Routes>
    </Router>;
};
