import React, {Component} from 'react';
import {withNavigation} from "../../../../utils/WithPlusClass";
import styles from "../../engineer/detail/index.module.css"
import PageTitle from "../../../../components/title/PageTitle";
import {message, Steps} from "antd";
import RenderFileView from "../../../../components/file/RenderFileView";
import DeviceApi from "../../../../api/device/DeviceApi";
import RenderFeedbackScore from "../../../../components/workorder/RenderFeedbackScore";
import RenderWorkorderProgress from "../../../../components/workorder/RenderWorkorderProgress";
import RenderWorkorderServiceType from "../../../../components/workorder/RenderWorkorderServiceType";
import CustomerApi from "../../../../api/customer/CustomerApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-17 11:07:40
 * @description  : 远程设备详情页面
 */
class RemoteDeviceDetail extends Component {

    state = {
        deviceList: [],
        deviceLogs: [],
        deviceWorkorderList: [],
        customer: {}
    }

    componentDidMount() {
        if (this.props?.location?.state?.deviceId === undefined) {
            message.error("无设备ID!")
            return;
        }
        this.requestDeviceDetail();
        this.requestDeviceLogs();
        this.requestAllDeviceWorkorder();
    }

    /**
     * 查询设备详细信息
     */
    requestDeviceDetail = () => {
        DeviceApi.findDeviceDetail(this.props.location.state.deviceId).then(res => {
            this.setState({deviceList: res.data});
            this.requestDeviceClient(res.data.customerId)
        }).catch(err => {
            console.error("request device detail failed", err)
        })
    }

    /**
     * 请求设备日志信息
     */
    requestDeviceLogs = () => {
        DeviceApi.findDeviceLogs({deviceId: this.props.location.state.deviceId}).then(res => {
            this.setState({
                deviceLogs: res.data.map(item => ({
                    title: <div style={{color: "#BEC3FF", fontSize: "14px"}}>{item.log}</div>,
                }))
            })
        }).catch(err => {
            console.error("request device logs failed", err)
        })
    }

    /**
     * 查询客户信息
     */
    requestDeviceClient = (customerId) => {
        CustomerApi.findCustomer(customerId).then(res => {
            this.setState({customer: res.data})
        }).catch(err => {
            console.error("request device client failed", err)
        })
    }

    /**
     * 查询设备所有工单
     */
    requestAllDeviceWorkorder = () => {
        DeviceApi.findAllDeviceWorkorder({
            resourceId: this.props.location.state.deviceId,
            pageSize: 99999
        }).then(res => {
            console.log(res)
            this.setState({
                deviceWorkorderList: res.data.records
            })
        }).catch(err => {
            console.error("request all device workorder failed", err)
        })
    }

    render() {
        const {customer} = this.state
        return (<div className={styles.device_detail_page}>
            <PageTitle title="赣州市信创数字化设备监控中心" backIcon/>
            <div className={styles.device_detail_main}>
                <div className={styles.device_detail_box}>
                    <div className={styles.device_detail_content}>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_customer_icon.png")}
                                     alt="" className={styles.device_detail_item_icon}/>
                                所属客户:
                            </span>
                            <span className={styles.device_detail_item_value}>{customer?.name ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_name_icon.png")}
                                     alt="" className={styles.device_detail_item_icon}/>
                                设备名称:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.name ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_expire_time_icon.png")}
                                     alt="" className={styles.device_detail_item_icon}/>
                                过保时间:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.warrantyPeriod ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_descriptions_icon.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                描述信息:
                            </span>
                            <span
                                className={`${styles.device_detail_item_value} ${styles.device_detail_descriptions}`}>{this.state.deviceList?.description ?? "-"}</span>
                        </div>
                    </div>
                    <div className={styles.device_detail_content}>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_customer_department_icon.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                客户单位:
                            </span>
                            <span className={styles.device_detail_item_value}>{customer?.department?.name ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_type_icon.png")}
                                     alt="" className={styles.device_detail_item_icon}/>
                                设备型号:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.deviceType?.manufacturer?.name ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_address_icon.png")}
                                     alt="" className={styles.device_detail_item_icon}/>
                                地址信息:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.address ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_file_icon.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                附件信息:
                            </span>
                            <span className={`${styles.device_detail_item_value} ${styles.device_detail_item_file}`}>
                                <RenderFileView file={this.state.deviceList?.files ?? []}/>
                            </span>
                        </div>
                    </div>
                    <div className={styles.device_detail_content}>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_id_icon.png")}
                                     alt="" className={styles.device_detail_item_icon}/>
                                设备编号:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.id ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_install_time_icon.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                安装时间:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.installTime ?? "-"}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_install_time_icon.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                门牌号码:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.storey === "" ? "-" : this.state.deviceList.storey}</span>
                        </div>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img src={require("../../../../asset/device/detail/device_detail_log_icon.png")}
                                     alt="" className={styles.device_detail_item_icon}/>
                                日志信息:
                            </span>
                            <span className={`${styles.device_detail_item_value} ${styles.device_detail_log}`}>
                                 <Steps
                                     progressDot
                                     current={8}
                                     size="small"
                                     direction="vertical"
                                     className={styles.device_detail_step}
                                     items={this.state.deviceLogs}/>
                            </span>
                        </div>

                    </div>
                </div>
                <div className={`${styles.device_detail_box} ${styles.device_manufacturer_info}`}>
                    <div className={`${styles.device_detail_content}`}>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label} style={{flex: 2.4}}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_manufacturer_icon.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                制造商名称:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.deviceType?.manufacturer?.name ?? "-"}</span>
                        </div>
                    </div>
                    <div className={styles.device_detail_content}>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_manufacturer_phone.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                联系方式:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.deviceType?.manufacturer?.contactsCellphone ?? "-"}</span>
                        </div>
                    </div>
                    <div className={styles.device_detail_content}>
                        <div className={styles.device_detail_item}>
                            <span className={styles.device_detail_item_label}>
                                <img
                                    src={require("../../../../asset/device/detail/device_detail_manufacturer_address_icon.png")}
                                    alt="" className={styles.device_detail_item_icon}/>
                                地址信息:
                            </span>
                            <span
                                className={styles.device_detail_item_value}>{this.state.deviceList?.deviceType?.manufacturer?.address ?? "-"}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.device_detail_workorder}>
                    <div className={styles.device_detail_workorder_title}>
                        设备工单
                    </div>
                    <div className={styles.device_detail_workorder_table}>
                        <div className={styles.device_detail_table_title}>
                            <span className={styles.device_detail_table_title_label}>ID</span>
                            <span className={styles.device_detail_table_title_label}>处理进度</span>
                            <span className={styles.device_detail_table_title_label}>服务类型</span>
                            <span className={styles.device_detail_table_title_label}>客户姓名</span>
                            <span className={styles.device_detail_table_title_label}>客户电话</span>
                            <span className={styles.device_detail_table_title_label}>填单人</span>
                            <span className={styles.device_detail_table_title_label}>处理人</span>
                            <span className={styles.device_detail_table_title_label}>创建时间</span>
                            <span className={styles.device_detail_table_title_label}>满意度</span>
                        </div>
                        <div className={styles.device_detail_table_content}>
                            {this.state.deviceWorkorderList ? this.state.deviceWorkorderList.map(item => <div
                                className={styles.device_detail_table_item} key={item.id}>
                                <span className={styles.device_detail_table_item_label}>{item?.id ?? "-"}</span>
                                <span
                                    className={styles.device_detail_table_item_label}>{RenderWorkorderProgress(item?.progress)}</span>
                                <span
                                    className={styles.device_detail_table_item_label}>{RenderWorkorderServiceType(item?.type)}</span>
                                <span className={styles.device_detail_table_item_label}>{item?.clientName ?? "-"}</span>
                                <span
                                    className={styles.device_detail_table_item_label}>{item?.clientCellphone ?? "-"}</span>
                                <span
                                    className={styles.device_detail_table_item_label}>{item?.creatorName ?? "-"}</span>
                                <span
                                    className={styles.device_detail_table_item_label}>{item?.handlerName ?? "-"}</span>
                                <span className={styles.device_detail_table_item_label}>{item?.createTime ?? "-"}</span>
                                <span
                                    className={styles.device_detail_table_item_label}>{RenderFeedbackScore(item?.feedbackScore)}</span>
                            </div>) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default withNavigation(RemoteDeviceDetail)
