import {get, post} from "../../https/index";

const HistorApi = {
    /**
     * 分页查询
     * @returns {Promise<AxiosResponse<any>>}
     */
    page: params => get("resource/ahri/history/page", params),

    /**
     * 查询历史详情
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: id => get(`/resource/ahri/history/detail/${id}`),
    /**
     * 根据设备sn查询设备信息
     * @param sn
     * @returns {Promise<AxiosResponse<any>>}
     */
    findBySn: sn => get(`/resource/ahri/ahri/detail/sn/${sn}`),
}

export default HistorApi