import React, {Component} from 'react';

import styles from "./css/center.module.css"
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import EdgeBorder from "../../../../../components/box/EdgeBorder";
import {formatDate, getToday, toValuesForToday} from "../../../../../utils/tool";
import {DatePicker, Skeleton} from "antd";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-14 10:49:27
 * @description  : 工程师详情页面中间内容
 */
export default class DetailCenterContent extends Component {

    state = {
        toBeReceivedWorkorderNumber: 0,
        toBeHandleWorkorderNumber: 0,
        timeoutWorkorderNumber: 0,
        workorderNumberToThirtyDay: 0,
        workorderTimeToThirtyDay: 0,
        workStateTimeToThirtyDay: 0,
        ongoingWorkorder: {},
        personWorkSituation: [],
        todaytime: getToday(),
        // 0 离线 1 暂时离开 2 在线 3 忙碌
        SituationColors: {
            "0":{
                background:  "#0CA9FF",
                text: "离线"
            },
            "1": {
                background:  "#0CA9FF",
                text: "暂时离开"
            },
            "2": {
                background:  "#00FF7C",
                text: "在线"
            },
            "3": {
                background:  "#FFDC31",
                text: "忙碌"
            },
            "4": {
                background:  "#1a1e45",
                text: "暂无数据"
            }
        },
        workRecCount: [
            {status: 1, name: "已接通工单数", value: 0},
            {status: 0,name: "未接通工单数", value: 0},
            {status: 2,name: "已转工单数", value: 0}
        ]
    }

    componentDidMount() {
        this.requestAccountToBeReceivedWorkorderNumber();
        this.requestAccountToBeHandleWorkorderNumber();
        this.requestAccountTimeoutWorkorderNumber();
        this.requestAccountOngoingWorkorder();
        this.requestAccountWorkorderNumberToThirtyDay();
        this.requestAccountWorkorderTimeToThirtyDay();
        this.requestAccountWorkStateTimeToThirtyDay();
        this.renderPersonWorkSituation()
        this.renderTaiFoxWorkorder(0)
        this.renderTaiFoxWorkorder(1)
        this.renderTaiFoxWorkorder(2)
    }

    /**
     * 获取用户待接收工单数
     */
    requestAccountToBeReceivedWorkorderNumber = () => {
        WorkorderApi.findWorkorderNumber({handlerId: this.props.accountId, progress: 5}).then(res => {
            this.setState({toBeReceivedWorkorderNumber: res.data})
        }).catch(err => {
            console.error("request account to be received workorder number failed", err)
        })
    }

    /**
     * 获取用户待处理工单数
     */
    requestAccountToBeHandleWorkorderNumber = () => {
        WorkorderApi.findWorkorderNumber({handlerId: this.props.accountId, progress: 9}).then(res => {
            this.setState({toBeHandleWorkorderNumber: res.data})
        }).catch(err => {
            console.error("request account to be handle workorder number failed", err)
        })
    }

    /**
     * 获取用户过期工单数
     */
    requestAccountTimeoutWorkorderNumber = () => {
        WorkorderApi.findDelayedWorkorder({handlerId: this.props.accountId, delay: 1}).then(res => {
            this.setState({timeoutWorkorderNumber: res.data})

        }).catch(err => {
            console.error("request account timeout workorder number failed", err)
        })
    }

    /**
     * 请求用户正在进行的工单
     */
    requestAccountOngoingWorkorder = () => {
        WorkorderApi.ongoingWorkorder(this.props.accountId).then(res => {
            this.setState({ongoingWorkorder: res.data})
        }).catch(err => {
            console.error("request account ongoing workorde failed", err)
        })
    }

    /**
     * 获取用户近三十天处理工单数
     */
    requestAccountWorkorderNumberToThirtyDay = () => {
        WorkorderApi.findAccountWorkorderNumberToThirtyDay(this.props.accountId).then(res => {
            this.setState({workorderNumberToThirtyDay: res.data})
        }).catch(err => {
            console.error("request account workorde number to thirty day failed", err)
        })
    }

    /**
     * 获取用户近三十天处理工单时长
     */
    requestAccountWorkorderTimeToThirtyDay = () => {
        WorkorderApi.findAccountWorkorderTimeToThirtyDay(this.props.accountId).then(res => {
            this.setState({workorderTimeToThirtyDay: (res.data / 3600).toFixed(2)})
        }).catch(err => {
            console.error("request account workorder time to thirty day failed", err)
        })
    }

    /**
     * 获取用户近三十天工作状态时长
     */
    requestAccountWorkStateTimeToThirtyDay = () => {
        WorkorderApi.findAccountWorkStateTimeToThirtyDay(this.props.accountId).then(res => {
            this.setState({workStateTimeToThirtyDay: (res.data / 3600).toFixed(2)})
        }).catch(err => {
            console.error("request account workorder time to thirty day failed", err)
        })
    }

    /**
     * 获取个人状态统计
     * @returns {JSX.Element}
     */
    renderPersonWorkSituation() {
        let param = {
            accountId: 1,
            date: this.state.todaytime
        }
        WorkorderApi.findAccountWorkState(param).then(res => {
            this.setState({personWorkSituation: toValuesForToday(res.data) })
            console.log(toValuesForToday(res.data))

        }).catch(err => {
            console.error("request person Work Situation to thirty day failed", err)
        })
    }

    /**
     * 上一周下一周按钮
     */
    renderWeekButton(buttonType) {
        switch (buttonType) {
            case "lastWeek":
                let lastWeek = new Date(this.state.todaytime);
                lastWeek.setDate(lastWeek.getDate() - 7);
                this.setState({todaytime: formatDate(lastWeek)}, () => {
                    console.log(formatDate(lastWeek))
                    this.renderPersonWorkSituation()
                })
                break;
            case "nextWeek":
                let nextWeek = new Date(this.state.todaytime);
                nextWeek.setDate(nextWeek.getDate() + 7)
                this.setState({todaytime: formatDate(nextWeek)}, () => {
                    console.log(this.state.todaytime)
                    this.renderPersonWorkSituation()
                })
        }
    }

    /**
     * 查询泰狐智能运维助手在线接单情况
     * @returns {JSX.Element}
     */
    renderTaiFoxWorkorder(status) {
        WorkorderApi.findWorkRecCount({accountId: this.props.accountId, status}).then(res => {
           let newWorkRecCount = this.state.workRecCount
            // 使用 find 方法查找并更新对应的对象
            const itemToUpdate = newWorkRecCount.find(item => item.status === status);
            if (itemToUpdate) {
                itemToUpdate.value = res.data;
            }
            this.setState({workRecCount: newWorkRecCount})
        }).catch(err => {
            console.error("request tai fox workorder failed", err)
        })
    }

    render() {
        const {
            toBeReceivedWorkorderNumber,
            toBeHandleWorkorderNumber,
            timeoutWorkorderNumber,
            ongoingWorkorder,
            workorderNumberToThirtyDay,
            workorderTimeToThirtyDay,
            workStateTimeToThirtyDay
        } = this.state
        return (<div className={styles.engineer_center_view}>
            <div className={styles.index_card_view}>
                <div className={styles.the_receive_workorder}>
                    <span className={styles.index_card_num}>{toBeReceivedWorkorderNumber}</span>
                    <span className={styles.index_card_label}>待接收工单</span>
                </div>
                <div className={styles.the_handle_workorder}>
                    <span className={styles.index_card_num}>{toBeHandleWorkorderNumber}</span>
                    <span className={styles.index_card_label}>待处理工单</span>
                </div>
                <div className={styles.on_time_workorder}>
                    <span className={styles.index_card_num}>{timeoutWorkorderNumber}</span>
                    <span className={styles.index_card_label}>超时工单</span>
                </div>
            </div>
            {/*<div className={styles.engineer_work_detail_view}>*/}
            {/*    <div className={styles.engineer_work_detail_item}>*/}
            {/*        <img src={require("../../../../../asset/engineer/detail/engineer_address_img.png")} alt=""*/}
            {/*             className={styles.engineer_work_item_img}/>*/}
            {/*        <div className={styles.engineer_work_detail_content}>*/}
            {/*            <span*/}
            {/*                className={styles.engineer_work_detail_content_title}>{ongoingWorkorder === null ? "暂无工单" : ongoingWorkorder.clientDepartmentAddress}</span>*/}
            {/*            <span className={styles.engineer_work_detail_content_value}>正在处理工单</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={styles.engineer_work_detail_item}>*/}
            {/*        <img src={require("../../../../../asset/engineer/detail/work_time_icon.png")} alt=""*/}
            {/*             className={styles.engineer_work_item_img}/>*/}
            {/*        <div className={styles.engineer_work_detail_content}>*/}
            {/*            <span className={styles.engineer_work_detail_content_title}>{workStateTimeToThirtyDay}(小时)</span>*/}
            {/*            <span className={styles.engineer_work_detail_content_value}>近30天工作状态时长</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={styles.engineer_work_detail_item}>*/}
            {/*        <img src={require("../../../../../asset/engineer/detail/workorder_num_icon.png")} alt=""*/}
            {/*             className={styles.engineer_work_item_img}/>*/}
            {/*        <div className={styles.engineer_work_detail_content}>*/}
            {/*            <span className={styles.engineer_work_detail_content_title}>{workorderNumberToThirtyDay}</span>*/}
            {/*            <span className={styles.engineer_work_detail_content_value}>近30天处理工单数</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={styles.engineer_work_detail_item}>*/}
            {/*        <img src={require("../../../../../asset/engineer/detail/workorder_time_icon.png")} alt=""*/}
            {/*             className={styles.engineer_work_item_img}/>*/}
            {/*        <div className={styles.engineer_work_detail_content}>*/}
            {/*            <span className={styles.engineer_work_detail_content_title}>{workorderTimeToThirtyDay}(小时)</span>*/}
            {/*            <span className={styles.engineer_work_detail_content_value}>近30天处理工单时长</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <EdgeBorder/>*/}
            {/*</div>*/}

            <div className={styles.engineer_work_detail_view}>

                <div className={styles.person_work_tongj_view}>
                    <div className={styles.title}>
                        泰狐智能运维助手在线接单情况
                    </div>
                    <div className={styles.tongj_view}>
                        {this.state.workRecCount.map((item, index) => (
                            <div className={styles.tongj_view_item}>
                                <div className={styles.tongj_view_item_num}>
                                    {item.value}
                                </div>
                                <div className={styles.tongj_view_item_title}>
                                    {item.name}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <div className={styles.person_work_situation_view60}>
                    <div className={styles.title}>
                        个人状态({this.state.todaytime.substring(0, 4)})
                        <div className={styles.card_button_view}>
                            <span className={styles.card_button_view_left} onClick={ ()=>this.renderWeekButton('lastWeek')} >&lt;</span>
                            <span className={styles.card_button_view_right} onClick={()=>this.renderWeekButton('nextWeek')}>&gt; </span>
                        </div>
                    </div>
                    <div className={styles.mychart_bar}>
                        <div className={styles.mychart_bar_yAxis}>
                            <span>23:59</span>
                            <span>20:00</span>
                            <span>16:00</span>
                            <span>12:00</span>
                            <span>08:00</span>
                            <span>04:00</span>
                            <span>00:00</span>
                        </div>
                            {this.state.personWorkSituation.map((item, index) => (
                                <div key={index} className={styles.mychart_bar_item}>
                                    {item.detail.map((detailitem, sizeIndex) => (
                                        <span key={sizeIndex} title={this.state.SituationColors[detailitem.status].text}
                                              style={{
                                                  height: `${(detailitem.value / 24) * 100}%`,
                                                  background: `${this.state.SituationColors[detailitem.status].background}`
                                              }}></span>
                                    ))}
                                </div>
                            ))}
                    </div>
                    <div className={styles.mychart_bar_xAxis_item}>
                        <span></span>
                        {this.state.personWorkSituation.map((item, index) => (
                            <span key={index}>{item.date.substring(5, 10).replace("-", "/")}</span>
                        ))}
                    </div>
                </div>
                <EdgeBorder/>
            </div>

            </div>
        )
    }
}
